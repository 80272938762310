import React from "react"
import SEO from "@components/common/SEO"
import { defaultBgColor } from "@src/build/constants"
import useGeneralContext from "@components/common/hoc/useGeneralContext"

function NotFound() {
  const {
    currentBgColor,
    setCurrentBgColor,
    isHome,
    setIsHome,
  } = useGeneralContext()

  const seo = {
    title: "Sunday Motoring Club | Page Not Found",
    noIndex: true,
  }

  React.useEffect(() => {
    if (currentBgColor !== defaultBgColor) {
      setCurrentBgColor(defaultBgColor)
    }

    if (isHome) {
      setIsHome(false)
    }
  }, [currentBgColor])

  return (
    <React.Fragment>
      <SEO seo={seo} />
      <h1 className="font-helvetica text-large absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        404
      </h1>
    </React.Fragment>
  )
}

export default NotFound
